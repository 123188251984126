.contactDiv {
  height: 100vh;
  background-color: var(--nav-bg-lighter3);
  margin-top: -10vh;
}

#contactHeader {
  font-size: 10vh;
  color: var(--nav-blue);
}

.titleDiv {
  position: relative;
  text-align: left;
  padding-left: 10vh;
}

#contactMe {
  color: white;
  font-size: 2.5vh;
}
