:root {
  --nav-blue: #4cabe2;
  --nav-bg: #0c0c0c;
  --name-purple: #a161d4;
  --nav-bg-lighter: #181818;
  --nav-bg-lighter2: #1f1e1e;
  --text-white: #ccc9c9;
  --subtitle-yellow: #ecdb3a;
}

.projectsDiv {
  height: 240vh;
  background-color: var(--nav-bg-lighter);
  padding-top: 10vh;
  margin-left: -10vh;
}

#projectsHeader {
  font-size: 10vh;
  color: var(--nav-blue);
}

.titleDiv {
  position: relative;
  text-align: left;
  padding-left: 10vw;
}

.projectDisplayDiv {
  margin-bottom: 10vh;
}

.projectInfoDiv {
  float: left;
  width: 40vw;
  border: solid white 1px;
  height: 50vh;
  color: var(--text-white);
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 2vh;
  background-color: var(--nav-bg-lighter2);
}
.projectDisplayContainerDiv {
  display: flex;
  flex-direction: column;
}

.projectPhotoDiv img {
  width: 38vw;
  float: left;
  height: 52vh;
  padding-left: 6vw;
}

#projectDescription {
  margin-bottom: 4vh;
}

li img {
  height: 6vh;
}

.projectTitle {
  color: var(--subtitle-yellow);
}

#builtWithLabel {
  color: var(--subtitle-yellow);
}

.builtWithList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
