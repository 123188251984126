:root {
  --nav-blue: #4cabe2;
  --nav-bg: #0c0c0c;
  --name-purple: #a161d4;
  --nav-bg-lighterer: #202020;
}

.aboutDiv {
  height: 100vh;
  background-color: var(--nav-bg-lighterer);
  margin-top: -10vh;
}

#aboutHeader {
  font-size: 10vh;
  color: var(--nav-blue);
}

.titleDiv {
  position: relative;
  text-align: left;
  padding-left: 10vh;
}

#aboutMe {
  font-size: 2.5vh;
  color: white;
}
