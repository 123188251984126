a {
  text-decoration: none;
}

a:visited {
  color: var(--nav-blue);
}

#myName {
  color: var(--name-purple);
  font-size: 1.5vmax;
  margin-left: 5vw;
  margin-top: 2vh;
  margin-right: 15vw;
}

.navBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 6vh;
  position: fixed;
  z-index: 1;
  top: 0px;
  width: 100vw;
  text-align: left;
  background-color: var(--nav-bg);
}

.PAC li {
  margin-right: 6vw;
  margin-top: 2vh;
  font-size: 2.3vh;
}

.Socials {
  display: flex;
  justify-content: flex-end;
  height: 8vh;
  color: #e0a06b;
  margin-left: 5vw;
}

.Socials li {
  margin-right: 8vw;
  margin-top: 1vh;
}

.navBarOption {
  color: var(--nav-blue);
}

.navBar img {
  height: 4vh;
  margin-top: 1vh;
}

.PAC {
  display: flex;
  flex-direction: row;
  height: 6vh;
  color: var(--nav-blue);
  margin-right: 4vw;
}

.Resume {
  color: orange;
}

ul {
  list-style-type: none;
}
